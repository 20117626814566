import * as THREE from "three";
import { Chunk } from "./chunk";

export class Elsa {
  static GRAVITY = 30;
  static JUMP_FORCE = Math.sqrt(Elsa.GRAVITY * 2 * 1.2522);

  constructor(scene, position, rotation) {
    // Altura de los ojos 1.62

    this.personaje = new THREE.Group();
    this.personaje.visible = false;
    scene.add(this.personaje);

    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.running = false;
    this.verticalSpeed = 0;
    this.lastTimeEyes = 0;
    this.eyeOffset = 0;
    this.onGround = true;

    this.scene = scene;
    this.position = position;
    this.rotation = rotation;

    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);

    this.generateGeometries();
  }

  onKeyDown = event => {
    this.running = event.ctrlKey;
    if (event.ctrlKey) {
      event.preventDefault();
    }
    //console.log(event.code);
    switch (event.code) {
      case "KeyW":
        this.moveForward = true;
        break;
      case "KeyA":
        this.moveLeft = true;
        break;
      case "KeyS":
        this.moveBackward = true;
        break;
      case "KeyD":
        this.moveRight = true;
        break;
      case "Space":
        this.doJump = true;
        break;
    }
  };

  onKeyUp = event => {
    event.preventDefault();
    this.running = event.ctrlKey;
    switch (event.code) {
      case "KeyW":
        this.moveForward = false;
        break;
      case "KeyA":
        this.moveLeft = false;
        break;
      case "KeyS":
        this.moveBackward = false;
        break;
      case "KeyD":
        this.moveRight = false;
        break;
      case "Space":
        this.doJump = false;
        break;
    }
  };

  startJump() {
    if (this.onGround) {
      this.onGround = false;
      this.verticalSpeed = Elsa.JUMP_FORCE;
    }
  }

  setRotation(rotation) {
    this.rotation.y = rotation;
  }

  generateGeometries() {
    // Cabeza
    this.geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
    let cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0xff0000 }));
    cube.position.set(0, -0.5 / 2 + 0.65 + 0.65 + 0.5 + 0.5, 0);
    this.personaje.add(cube);
    this.head = cube;

    // Cuerpo
    this.geometry = new THREE.BoxGeometry(0.44, 0.65, 0.25);
    cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
    cube.position.set(0, -0.65 / 2 + 0.65 + 0.65 + 0.5, 0);
    this.personaje.add(cube);
    this.body = cube;

    // Pierna Izquierda
    this.geometry = new THREE.BoxGeometry(0.215, 0.65, 0.25);
    cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0xff0000 }));
    cube.position.set(0.215 / 2, -0.65 / 2 + 0.65 + 0.5, 0);
    this.personaje.add(cube);
    this.leftLeg = cube;

    // Pierna Derecha
    this.geometry = new THREE.BoxGeometry(0.215, 0.65, 0.25);
    cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0x0000ff }));
    cube.position.set(-0.215 / 2, -0.65 / 2 + 0.65 + 0.5, 0);
    this.personaje.add(cube);
    this.rightLeg = cube;

    // Brazo Izquierdo
    this.geometry = new THREE.BoxGeometry(0.19, 0.74, 0.25);
    cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0xff0000 }));
    cube.position.set(0.19 / 2 + 0.22, -0.74 / 2 + 0.65 + 0.65 + 0.5, 0);
    this.personaje.add(cube);
    this.leftArm = cube;

    // Brazo Derecho
    this.geometry = new THREE.BoxGeometry(0.19, 0.74, 0.25);
    cube = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ color: 0x0000ff }));
    cube.position.set(-0.19 / 2 - 0.22, -0.74 / 2 + 0.65 + 0.65 + 0.5, 0);
    this.personaje.add(cube);
    this.rightArm = cube;
  }

  updateGeometries() {
    this.personaje.rotation.y = this.rotation.y;
    this.personaje.position.x = this.position.x;
    this.personaje.position.y = this.position.y - 0.5;
    this.personaje.position.z = this.position.z;
  }

  update(timestamp, lastTimestamp, deltaTime) {
    const speedWalking = 4.317;
    const speedRunning = 5.612;
    const speed = (this.running ? speedRunning : speedWalking) * deltaTime;

    let direction = new THREE.Vector3();
    direction.x = Math.sin(this.rotation.y);
    direction.z = Math.cos(this.rotation.y);
    direction.normalize();
    let newSpeed = -((this.moveForward ? speed : 0) - (this.moveBackward ? speed : 0));
    let moving = newSpeed !== 0;
    this.position.x += direction.x * newSpeed;
    this.position.z += direction.z * newSpeed;

    direction = new THREE.Vector3();
    direction.x = Math.sin(this.rotation.y + Math.PI / 2);
    direction.z = Math.cos(this.rotation.y + Math.PI / 2);
    direction.normalize();
    newSpeed = -((this.moveLeft ? speed : 0) - (this.moveRight ? speed : 0));
    moving = moving | (newSpeed !== 0);
    this.position.x += direction.x * newSpeed;
    this.position.z += direction.z * newSpeed;

    if (this.doJump) {
      this.startJump();
    }

    //console.log(this.position.x, this.position.y, this.position.z, block);
    const newPosY = this.position.y + this.verticalSpeed * deltaTime;
    const blockBelowCurrentPos = Chunk.getGlobalBlock(this.position.x, Math.floor(this.position.y - 1), this.position.z);
    if (!(this.onGround && this.verticalSpeed === 0 && blockBelowCurrentPos !== 0)) {
      const blockBelowNewPos = Chunk.getGlobalBlock(this.position.x, Math.floor(newPosY), this.position.z);
      this.onGround = Math.floor(newPosY) < Math.floor(this.position.y) && blockBelowNewPos !== 0;
      if (this.onGround) {
        this.position.y = Math.floor(this.position.y);
        this.verticalSpeed = 0;
      } else {
        this.verticalSpeed -= Elsa.GRAVITY * deltaTime;
        this.position.y = newPosY;
      }
    }

    if (this.onGround && moving) {
      this.lastTimeEyes += deltaTime * 15;
      this.eyeOffset = Math.sin(this.lastTimeEyes) * 0.05;
    }

    Chunk.setPlayerPosition(this.position.x, this.position.z);

    this.updateInfo();

    this.updateGeometries();
  }

  updateInfo() {
    const infoElement = document.getElementById("info");
    let text = `OnGround: ${this.onGround}<br>`;
    text += `Vertical Speed: ${this.verticalSpeed.toFixed(2)}<br>`;
    text += `XYZ: ${this.position.x.toFixed(5)} / ${this.position.y.toFixed(5)} / ${this.position.z.toFixed(5)}<br>`;
    text += `Block: ${Math.floor(this.position.x)} / ${Math.floor(this.position.y)} / ${Math.floor(this.position.z)} [${Math.floor(this.position.x) % 16} ${Math.floor(this.position.z) % 16}]<br>`;
    const chunk = Chunk.getGlobalChunk(this.position.x, this.position.z);
    text += `Chunk: ${chunk.chunkX} / ${chunk.chunkZ}<br>`;

    infoElement.innerHTML = text;
  }
}
