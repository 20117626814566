import * as THREE from "three";
import MaterialFactory from "./material.js";
import dat from "dat.gui";
import Stats from "stats.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
import { initializeScene } from "./setupScene.js";
import { setupLights } from "./setupLights.js";
import { Chunk } from "./chunk.js";
import { Elsa } from "./elsa.js";

export class World {
  static instance = null;
  static wireframe = false;
  static WAITFPS = 1;
  static updateCameras = false;

  constructor() {
    if (World.instance) {
      throw new Error("You can only create one instance of World!");
    }

    World.instance = this;

    this.cameraOrbit = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.cameraPointer = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);

    this.lastTimestamp = 1;

    this.fps;
    this.lastFrameTime = 0;
    this.waitFPS = World.WAITFPS;
    this.stats = new Stats();

    this.posX = 8;
    this.posZ = 8;

    this.initializeStats();

    // eslint-disable-next-line no-unused-vars
    const { scene, renderer, cleanup } = initializeScene();
    this.scene = scene;
    this.renderer = renderer;

    this.axesHelper = new THREE.AxesHelper(8);
    this.axesHelper.position.set(8, 64, 11);
    this.axesHelper.visible = false;

    this.scene.add(this.axesHelper);

    setupLights(this.scene);

    this.player = new Elsa(scene, new THREE.Vector3(6, 63, 16), new THREE.Vector3(0, 0, 0));

    this.firstPersonCamera = true;
    this.controlsOrbit = new OrbitControls(this.cameraOrbit, this.renderer.domElement);
    this.controlsOrbit.minDistance = 4;
    this.controlsOrbit.maxDistance = 4;
    this.controlsPointer = new PointerLockControls(this.cameraPointer, this.renderer.domElement);
    this.configureOrbitControls();
    this.configureLockControls();

    Chunk.setPlayerPosition(this.posX, this.posZ);

    const gui = new dat.GUI();
    gui
      .add(World, "wireframe")
      .name("Wireframe")
      .onChange(() => {
        MaterialFactory.updateWireframe();
      });

    gui.add(this.axesHelper, "visible").name("Axe Helper");
    gui
      .add(this, "firstPersonCamera")
      .name("First Person")
      .onChange(() => {
        this.changeCamera();
      });

    requestAnimationFrame(this.animate);

    document.addEventListener("keydown", this.onKeyDown);
  }

  cleanup() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);

    cancelAnimationFrame(this.requestId);
  }

  static getInstance() {
    if (!World.instance) {
      new World();
    }

    return World.instance;
  }

  onKeyDown = event => {
    // console.log(event.code);
    switch (event.code) {
      case "Digit5":
        this.firstPersonCamera = !this.firstPersonCamera;
        this.changeCamera();
        break;
    }
  };

  changeCamera() {
    if (this.firstPersonCamera) {
      this.controlsPointer.lock();
      this.player.personaje.visible = false;
    } else {
      this.controlsPointer.unlock();
      this.player.personaje.visible = true;
    }
  }

  initializeStats() {
    this.stats.showPanel(0);
    document.body.appendChild(this.stats.dom);
  }

  configureOrbitControls() {
    this.controlsOrbit.enableDamping = true;
    this.controlsOrbit.dampingFactor = 0.05;
  }

  configureLockControls() {
    document.body.addEventListener("click", () => {
      if (this.firstPersonCamera) {
        this.controlsPointer.lock();
      }
    });

    this.controlsPointer.addEventListener("unlock", () => {
      console.log("Pointer is unlocked");
    });
  }

  update(timestamp, lastTimestamp, deltaTime) {
    if (World.updateCameras) {
      this.cameraPointer.aspect = window.innerWidth / window.innerHeight;
      this.cameraPointer.updateProjectionMatrix();
      this.cameraOrbit.aspect = window.innerWidth / window.innerHeight;
      this.cameraOrbit.updateProjectionMatrix();
      World.updateCameras = false;
    }

    const euler = new THREE.Euler(0, 0, 0, "YXZ");
    euler.setFromQuaternion(this.cameraPointer.quaternion);

    this.player.setRotation(euler.y);
    this.player.update(timestamp, this.lastTimestamp, deltaTime);

    this.cameraPointer.position.x = this.player.position.x;
    this.cameraPointer.position.z = this.player.position.z;
    this.cameraPointer.position.y = this.player.position.y + 1.62 + this.player.eyeOffset;

    this.controlsOrbit.target.set(this.player.position.x, this.player.position.y + 1.62, this.player.position.z);
    this.controlsOrbit.update();
  }

  render() {
    this.renderer.render(this.scene, this.firstPersonCamera ? this.cameraPointer : this.cameraOrbit);
  }

  // eslint-disable-next-line no-unused-vars
  animate = (timestamp = 0) => {
    requestAnimationFrame(this.animate);
    this.stats.begin();

    if (this.lastFrameTime !== 0) {
      const delta = timestamp - this.lastFrameTime;
      this.fps = 1000 / delta;
    }

    this.lastFrameTime = timestamp;

    const deltaTime = (timestamp - this.lastTimestamp) / 1000.0; // Convertir a segundos

    // Actualiza el último timestamp
    this.lastTimestamp = timestamp;

    /*
    if (this.fps > 60) {
      this.waitFPS--;
      if (this.waitFPS <= 0) {
        this.waitFPS = World.WAITFPS;
        Chunk.OptimiceOneMore();
        this.lastFrameTime += 1000000;
      }
    }
*/
    Chunk.OptimiceOneMore();

    this.update(timestamp, this.lastTimestamp, deltaTime);

    this.render();

    this.stats.end();
  };
}
