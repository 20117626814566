import MaterialFactory from "./material.js";

export class BlockBase {
  static SIDE_RIGHT = 0;
  static SIDE_LEFT = 1;
  static SIDE_UP = 2;
  static SIDE_DOWN = 3;
  static SIDE_FRONT = 4;
  static SIDE_BACK = 5;

  constructor(blockId) {
    this.blockId = blockId;

    this.fileSides = [
      "block-empty.png", // 0
      "block-empty.png", // 1
      "block-empty.png", // 2
      "block-empty.png", // 3
      "block-empty.png", // 4
      "block-empty.png", // 5
    ];
  }

  getMaterial(side) {
    return MaterialFactory.getMaterial(`./${this.fileSides[side]}`);
  }

  setupGeometry(geometry, width, height) {
    const uv = geometry.attributes.uv;

    uv.setXY(0, 0, 1); // superior izquierdo
    uv.setXY(1, width, 1); // superior derecho
    uv.setXY(2, 0, 1 - height); // inferior izquierdo
    uv.setXY(3, width, 1 - height); // inferior derecho
  }
}

export class BlockDirt extends BlockBase {
  constructor() {
    super(1);
    this.fileSides = [
      "block-dirt.png", // 0
      "block-dirt.png", // 1
      "block-dirt.png", // 2
      "block-dirt.png", // 3
      "block-dirt.png", // 4
      "block-dirt.png", // 5
    ];
  }
}

export class BlockGrass extends BlockBase {
  constructor() {
    super(2);
    this.fileSides = [
      "block-grass_block-0145.png", // 0
      "block-grass_block-0145.png", // 1
      "block-grass_block-2.png", // 2
      "block-dirt.png", // 3
      "block-grass_block-0145.png", // 4
      "block-grass_block-0145.png", // 5
    ];
  }
}

export class BlockDiamond extends BlockBase {
  constructor() {
    super(3);
    this.fileSides = [
      "block-diamond_block.png", // 0
      "block-diamond_block.png", // 1
      "block-diamond_block.png", // 2
      "block-diamond_block.png", // 3
      "block-diamond_block.png", // 4
      "block-diamond_block.png", // 5
    ];
  }
}

export class BlockStoneBrick extends BlockBase {
  constructor() {
    super(4);
    this.fileSides = [
      "block-stonebrick.png", // 0
      "block-stonebrick.png", // 1
      "block-stonebrick.png", // 2
      "block-stonebrick.png", // 3
      "block-stonebrick.png", // 4
      "block-stonebrick.png", // 5
    ];
  }
}

export class BlockYellowWool extends BlockBase {
  constructor() {
    super(5);
    this.fileSides = [
      "block-yellow_wool.png", // 0
      "block-yellow_wool.png", // 1
      "block-yellow_wool.png", // 2
      "block-yellow_wool.png", // 3
      "block-yellow_wool.png", // 4
      "block-yellow_wool.png", // 5
    ];
  }
}

export class BlockStone extends BlockBase {
  constructor() {
    super(6);
    this.fileSides = [
      "block-stone.png", // 0
      "block-stone.png", // 1
      "block-stone.png", // 2
      "block-stone.png", // 3
      "block-stone.png", // 4
      "block-stone.png", // 5
    ];
  }
}

export class BlockCobblestone extends BlockBase {
  constructor() {
    super(7);
    this.fileSides = [
      "block-cobblestone.png", // 0
      "block-cobblestone.png", // 1
      "block-cobblestone.png", // 2
      "block-cobblestone.png", // 3
      "block-cobblestone.png", // 4
      "block-cobblestone.png", // 5
    ];
  }
}

export class BlockPatito extends BlockBase {
  constructor() {
    super(8);
    this.fileSides = [
      "block-patito-015.png", // 0
      "block-patito-015.png", // 1
      "block-patito-2.png", // 2
      "block-patito-3.png", // 3
      "block-patito-4.png", // 4
      "block-patito-015.png", // 5
    ];
  }
}

export class BlockPattern extends BlockBase {
  constructor() {
    super(9);
    this.fileSides = [
      "block-pattern-0.png", // 0
      "block-pattern-1.png", // 1
      "block-pattern-2.png", // 2
      "block-pattern-3.png", // 3
      "block-pattern-4.png", // 4
      "block-pattern-5.png", // 5
    ];
  }
}

export class BlockSand extends BlockBase {
  constructor() {
    super(10);
    this.fileSides = [
      "block-sand.png", // 0
      "block-sand.png", // 1
      "block-sand.png", // 2
      "block-sand.png", // 3
      "block-sand.png", // 4
      "block-sand.png", // 5
    ];
  }
}

export class BlockManager {
  constructor() {
    this.blocks = [];
    this.blocks[1] = new BlockDirt();
    this.blocks[2] = new BlockGrass();
    this.blocks[3] = new BlockDiamond();
    this.blocks[4] = new BlockStoneBrick();
    this.blocks[5] = new BlockYellowWool();
    this.blocks[6] = new BlockStone();
    this.blocks[7] = new BlockCobblestone();
    this.blocks[8] = new BlockPatito();
    this.blocks[9] = new BlockPattern();
    this.blocks[10] = new BlockSand();
  }

  static getInstance() {
    if (!BlockManager.instance) {
      BlockManager.instance = new BlockManager();
      Object.freeze(BlockManager.instance);
    }
    return BlockManager.instance;
  }
}
