import * as THREE from "three";
import TextureLoader from "./texture.js";
import { World } from "./world.js";

export default class MaterialFactory {
  constructor() {
    if (!MaterialFactory.cache) {
      MaterialFactory.cache = {};
    }
  }

  static getMaterial(texturePath) {
    if (!this.cache[texturePath]) {
      const texture = TextureLoader.load(texturePath);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      this.cache[texturePath] = new THREE.MeshPhongMaterial({ map: texture, wireframe: World.wireframe });
    }
    return this.cache[texturePath];
  }

  static updateWireframe() {
    for (const material in this.cache) {
      this.cache[material].wireframe = World.wireframe;
    }
  }
}

MaterialFactory.cache = {};
