import * as THREE from "three";

export function setupLights(scene) {
  const ambientLight = new THREE.AmbientLight(0xaabbcb, 2);
  scene.add(ambientLight);

  const sunlight = new THREE.DirectionalLight(0xfff4d6, 2);
  sunlight.position.set(0, 3, 0);
  sunlight.castShadow = true;
  sunlight.shadow.mapSize.width = 256;
  sunlight.shadow.mapSize.height = 256;
  sunlight.shadow.camera.near = 0.5;
  sunlight.shadow.camera.far = 50;
  sunlight.shadow.radius = 20;
  scene.add(sunlight);
}
