import * as THREE from "three";

class TextureLoader {
  constructor() {
    this.loader = new THREE.TextureLoader();
    this.loadedTextures = {};
  }

  static getInstance() {
    if (!TextureLoader.instance) {
      TextureLoader.instance = new TextureLoader();
      Object.freeze(TextureLoader.instance);
    }
    return TextureLoader.instance;
  }

  load(url) {
    if (!this.loadedTextures[url]) {
      this.loadedTextures[url] = this.loader.load(url);
      this.loadedTextures[url].magFilter = THREE.NearestFilter;
      this.loadedTextures[url].minFilter = THREE.NearestFilter;
    }

    return this.loadedTextures[url];
  }
}

const instance = TextureLoader.getInstance();

export default instance;
