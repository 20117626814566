import { BlockBase, BlockManager } from "./block";
import { createNoise2D } from "simplex-noise";
import alea from "alea";
import * as THREE from "three";
import { World } from "./world";

export class Chunk {
  static TEST_INIT_VALUE = -1;
  static CHUNK_WIDTH = 16;
  static CHUNK_HEIGHT = 256;
  static CHUNK_DEPTH = 16;
  static CHUNK_SIZE = Chunk.CHUNK_WIDTH * Chunk.CHUNK_DEPTH * Chunk.CHUNK_HEIGHT;

  static RENDER_DISTANCE = 4;
  // static RENDER_DISTANCE = 12;
  // static RENDER_DISTANCE = 45;
  static playerPositionX;
  static playerPositionZ;
  static queueOptimize = [];

  constructor(x, z) {
    if (!Chunk.chunks) {
      Chunk.chunks = {};
    }

    this.chunkX = x;
    this.chunkZ = z;
    this.isOptimized = false;

    this.blocks = new Array(Chunk.CHUNK_SIZE).fill(0);

    const prng = alea(1);
    this.noise2D = createNoise2D(prng);

    this.fillChunk();

    this.mustQueueOptimize();
  }

  mustQueueOptimize() {
    if (this.isOptimized || Chunk.queueOptimize.includes(this)) {
      return;
    }

    if (
      Chunk.isInCircle(this.chunkX * Chunk.CHUNK_WIDTH, this.chunkZ * Chunk.CHUNK_DEPTH, Chunk.playerPositionX, Chunk.playerPositionZ, Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH) ||
      Chunk.isInCircle(
        this.chunkX * Chunk.CHUNK_WIDTH,
        this.chunkZ * Chunk.CHUNK_DEPTH + 15,
        Chunk.playerPositionX,
        Chunk.playerPositionZ,
        Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH
      ) ||
      Chunk.isInCircle(
        this.chunkX * Chunk.CHUNK_WIDTH + 15,
        this.chunkZ * Chunk.CHUNK_DEPTH,
        Chunk.playerPositionX,
        Chunk.playerPositionZ,
        Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH
      ) ||
      Chunk.isInCircle(
        this.chunkX * Chunk.CHUNK_WIDTH + 15,
        this.chunkZ * Chunk.CHUNK_DEPTH + 15,
        Chunk.playerPositionX,
        Chunk.playerPositionZ,
        Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH
      )
    ) {
      Chunk.queueOptimize.push(this);
    }
  }

  static getIndex(x, y, z) {
    return (y * Chunk.CHUNK_DEPTH + z) * Chunk.CHUNK_WIDTH + x;
  }

  setBlock(x, y, z, block) {
    const index = Chunk.getIndex(x, y, z);
    this.blocks[index] = block;
  }

  getBlock(x, y, z) {
    const index = Chunk.getIndex(x, y, z);
    return this.blocks[index];
  }

  static isInCircle(px, py, cx, cy, radious) {
    return Math.sqrt(Math.pow(px - cx, 2) + Math.pow(py - cy, 2)) < radious;
  }

  getDistanceToPlayer() {
    return Math.sqrt(
      Math.pow(this.chunkX * Chunk.CHUNK_WIDTH + Chunk.CHUNK_WIDTH / 2 - Chunk.playerPositionX, 2) +
        Math.pow(this.chunkZ * Chunk.CHUNK_DEPTH + Chunk.CHUNK_DEPTH / 2 - Chunk.playerPositionZ, 2)
    );
  }

  static OptimiceOneMore() {
    if (Chunk.queueOptimize.length > 0) {
      let selected = 0;
      let distance = Chunk.queueOptimize[0].getDistanceToPlayer();
      for (let i = 1; i < Chunk.queueOptimize.length; i++) {
        const newDistance = Chunk.queueOptimize[i].getDistanceToPlayer();
        if (newDistance < distance) {
          distance = newDistance;
          selected = i;
        }
      }

      const chunk = Chunk.queueOptimize.splice(selected, 1);
      chunk[0].optimizeAllGeometries();
    }
  }

  static setPlayerPosition(x, z) {
    Chunk.playerPositionX = x;
    Chunk.playerPositionZ = z;

    for (let xp = -Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH + x; xp <= x + Chunk.RENDER_DISTANCE * Chunk.CHUNK_WIDTH; xp += Chunk.CHUNK_WIDTH) {
      for (let zp = -Chunk.RENDER_DISTANCE * Chunk.CHUNK_DEPTH + z; zp <= z + Chunk.RENDER_DISTANCE * Chunk.CHUNK_DEPTH; zp += Chunk.CHUNK_DEPTH) {
        const chunk = Chunk.getChunk(Math.floor(xp / Chunk.CHUNK_WIDTH), Math.floor(zp / Chunk.CHUNK_DEPTH));
        chunk.mustQueueOptimize();
        //chunk.optimizeAllGeometries();
      }
    }
  }

  static getGlobalBlock(x, y, z) {
    x = Math.floor(x);
    z = Math.floor(z);
    const chunk = this.getGlobalChunk(x, z);
    const internalX = x - chunk.chunkX * Chunk.CHUNK_WIDTH;
    const internalZ = z - chunk.chunkZ * Chunk.CHUNK_DEPTH;
    return chunk.getData(internalX, y, internalZ);
  }

  static getGlobalChunk(x, z) {
    x = Math.floor(x);
    z = Math.floor(z);
    return Chunk.getChunk(Math.floor(x / Chunk.CHUNK_WIDTH), Math.floor(z / Chunk.CHUNK_DEPTH));
  }

  fillChunk() {
    const scale = 0.02;
    const alturaMax = 19;
    for (let x = 0; x < Chunk.CHUNK_WIDTH; x++) {
      const realPosX = x + this.chunkX * Chunk.CHUNK_WIDTH;
      for (let z = 0; z < Chunk.CHUNK_DEPTH; z++) {
        const realPosZ = this.chunkZ * Chunk.CHUNK_DEPTH + z;
        const ruido = this.noise2D(realPosX * scale, realPosZ * scale);
        let altura = Math.floor(62 - alturaMax / 2 + ((ruido + 1) / 2) * alturaMax);
        if (altura < 63) {
          altura = 63;
        }
        for (let y = 0; y < altura; y++) {
          this.setBlock(x, y, z, y === altura - 1 ? (y >= 63 ? 2 : 10) : 1); // BlockGrass : BlockDirt
        }
      }
    }

    const base = 62;

    if (this.chunkX === 0 && this.chunkZ === 0) {
      for (let i = base + 1; i <= base + 3; i++) {
        this.setBlock(7, i, 9, 4); // BlockStoneBrick
        this.setBlock(7, i, 8, 4); // BlockStoneBrick
        this.setBlock(7, i, 7, 4); // BlockStoneBrick
        this.setBlock(8, i, 7, 4); // BlockStoneBrick
        this.setBlock(9, i, 7, 4); // BlockStoneBrick
        this.setBlock(9, i, 8, 4); // BlockStoneBrick
        this.setBlock(9, i, 9, 4); // BlockStoneBrick
      }

      this.setBlock(8, base + 3, 8, 4); // BlockStoneBrick
      this.setBlock(8, base + 3, 9, 4); // BlockStoneBrick

      this.setBlock(7, base + 3, 10, 4); // BlockStoneBrick
      this.setBlock(8, base + 3, 10, 4); // BlockStoneBrick
      this.setBlock(9, base + 3, 10, 4); // BlockStoneBrick

      this.setBlock(7, base, 11, 4); // BlockStoneBrick
      this.setBlock(8, base, 11, 4); // BlockStoneBrick
      this.setBlock(9, base, 11, 4); // BlockStoneBrick

      for (let i = 6; i <= 10; i++) {
        for (let j = 6; j <= 10; j++) {
          this.setBlock(i, base, j, 4); // BlockStoneBrick
        }
      }

      this.setBlock(0, base + 2, 0, 9); // BlockPattern

      let geometria = new THREE.BoxGeometry(1.125, 0.125, 0.125);
      let material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      let cubo = new THREE.Mesh(geometria, material);
      cubo.position.set(0.5, base + 2 + 0.5, 0.5);
      World.getInstance().scene.add(cubo);

      geometria = new THREE.BoxGeometry(0.125, 1.125, 0.125);
      material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      cubo = new THREE.Mesh(geometria, material);
      cubo.position.set(0.5, base + 2 + 0.5, 0.5);
      World.getInstance().scene.add(cubo);

      geometria = new THREE.BoxGeometry(0.125, 0.125, 1.125);
      material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
      cubo = new THREE.Mesh(geometria, material);
      cubo.position.set(0.5, base + 2 + 0.5, 0.5);
      World.getInstance().scene.add(cubo);

      this.setBlock(11, base + 2, 9, 8); // BlockPatito

      for (let i = 1; i <= base; i++) {
        this.setBlock(8, i, 8, 0);
      }

      for (let i = 6; i <= 10; i++) {
        for (let j = 6; j <= 10; j++) {
          for (let k = base - 9; k <= base - 6; k++) {
            this.setBlock(i, k, j, 0);
          }
        }
      }

      this.setBlock(7, base - 8, 7, 3); // BlockDiamond
    }
  }

  create2DArray(width, height, initialValue) {
    const array = new Array(width);

    for (let x = 0; x < width; x++) {
      array[x] = new Array(length);

      for (let y = 0; y < height; y++) {
        array[x][y] = initialValue;
      }
    }

    return array;
  }

  optimizeAllGeometries() {
    if (this.isOptimized) {
      return;
    }

    this.visibleSides = new Array(Chunk.CHUNK_SIZE).fill(0);

    for (let y = 0; y < Chunk.CHUNK_HEIGHT; y++) {
      for (let x = 0; x < Chunk.CHUNK_WIDTH; x++) {
        for (let z = 0; z < Chunk.CHUNK_DEPTH; z++) {
          const block = this.getBlock(x, y, z);
          if (block !== 0) {
            this.visibleSides[Chunk.getIndex(x, y, z)] = this.blockVisibleSides(x, y, z);
          }
        }
      }
    }

    // Derecha
    this.optimizeGeometries(BlockBase.SIDE_RIGHT);
    // Izquierda
    this.optimizeGeometries(BlockBase.SIDE_LEFT);
    // Arriba
    this.optimizeGeometries(BlockBase.SIDE_UP);
    // Abajo
    this.optimizeGeometries(BlockBase.SIDE_DOWN);
    // Frontal
    this.optimizeGeometries(BlockBase.SIDE_FRONT);
    // Trasera
    this.optimizeGeometries(BlockBase.SIDE_BACK);
    this.isOptimized = true;

    // Ya no hace falta
    this.visibleSides = null;
  }

  optimizeGeometries(sideP) {
    const side = 1 << sideP;
    let suma = 0;
    const initValue = Chunk.TEST_INIT_VALUE;

    switch (sideP) {
      case BlockBase.SIDE_RIGHT:
      case BlockBase.SIDE_LEFT:
        for (let x = 0; x < 16; x++) {
          let cont = 1;
          const test = this.create2DArray(256, 16, initValue);
          for (let y = 0; y < 256; y++) {
            for (let z = 0; z < 16; z++) {
              const block = this.getBlock(x, y, z);
              if (block === 0 || test[y][z] !== initValue || (this.visibleSides[Chunk.getIndex(x, y, z)] & side) === 0) {
                continue;
              }

              test[y][z] = cont;
              this.expandBlock01(test, x, y, z, side);
              cont++;
            }
          }

          if (cont > 1) {
            /*
            for (let y = 0; y < 256; y++) {
              let line = `${String(z).padStart(3, " ")}.${String(y).padStart(2, " ")}: `;
              for (let x = 0; x < 16; x++) {
                line += ` ${String(test[x][y][z]).padStart(3, " ")}`;
              }
              console.log(`${line}`);
            }
            console.log("--------------------");
*/
            suma += cont - 1;
          }
        }
        break;
      case BlockBase.SIDE_UP:
      case BlockBase.SIDE_DOWN:
        for (let y = 0; y < 256; y++) {
          let cont = 1;
          const test = this.create2DArray(16, 16, initValue);
          for (let z = 0; z < 16; z++) {
            for (let x = 0; x < 16; x++) {
              const block = this.getBlock(x, y, z);
              if (block === 0 || test[x][z] !== initValue || (this.visibleSides[Chunk.getIndex(x, y, z)] & side) === 0) {
                continue;
              }

              test[x][z] = cont;
              this.expandBlock23(test, x, y, z, side);
              cont++;
            }
          }

          if (cont > 1) {
            /*
              for (let z = 0; z < 16; z++) {
                let line = `${String(y).padStart(3, " ")}.${String(z).padStart(2, " ")}: `;
                for (let x = 0; x < 16; x++) {
                  line += ` ${String(test[x][y][z]).padStart(3, " ")}`;
                }
                console.log(`${line}`);
              }
              console.log("--------------------");
*/
            suma += cont - 1;
          }
        }
        break;
      case BlockBase.SIDE_FRONT:
      case BlockBase.SIDE_BACK:
        for (let z = 0; z < 16; z++) {
          let cont = 1;
          const test = this.create2DArray(16, 256, initValue);
          for (let y = 0; y < 256; y++) {
            for (let x = 0; x < 16; x++) {
              const block = this.getBlock(x, y, z);
              if (block === 0 || test[x][y] !== initValue || (this.visibleSides[Chunk.getIndex(x, y, z)] & side) === 0) {
                continue;
              }

              test[x][y] = cont;
              this.expandBlock45(test, x, y, z, side);
              cont++;
            }
          }

          if (cont > 1) {
            /*
            for (let y = 0; y < 256; y++) {
              let line = `${String(z).padStart(3, " ")}.${String(y).padStart(2, " ")}: `;
              for (let x = 0; x < 16; x++) {
                line += ` ${String(test[x][y][z]).padStart(3, " ")}`;
              }
              console.log(`${line}`);
            }
            console.log("--------------------");
*/
            suma += cont - 1;
          }
        }
        break;
    }

    return suma;
  }

  static getChunk(x, z) {
    const chunkId = `${x}x${z}`;

    if (!Chunk.chunks[chunkId]) {
      Chunk.chunks[chunkId] = new Chunk(x, z);
      // console.log(`Creado get: ${chunkId}`);
    }

    return Chunk.chunks[chunkId];
  }

  getData(x, y, z) {
    if (x === -1) {
      return Chunk.getChunk(this.chunkX - 1, this.chunkZ).getData(15, y, z);
    }

    if (x === 16) {
      return Chunk.getChunk(this.chunkX + 1, this.chunkZ).getData(0, y, z);
    }

    if (z === -1) {
      return Chunk.getChunk(this.chunkX, this.chunkZ - 1).getData(x, y, 15);
    }

    if (z === 16) {
      return Chunk.getChunk(this.chunkX, this.chunkZ + 1).getData(x, y, 0);
    }

    return this.getBlock(x, y, z);
  }

  blockVisibleSides(x, y, z) {
    let canShow = 0;

    // Derecha
    if (this.getData(x + 1, y, z) === 0) {
      canShow |= 1 << 0;
    }

    // Izquierda
    if (this.getData(x - 1, y, z) === 0) {
      canShow |= 1 << 1;
    }

    // Arriba
    if (y !== 255 && this.getData(x, y + 1, z) === 0) {
      canShow |= 1 << 2;
    }

    // Abajo
    if (y !== 0 && this.getData(x, y - 1, z) === 0) {
      canShow |= 1 << 3;
    }

    // Frontal
    if (this.getData(x, y, z + 1) === 0) {
      canShow |= 1 << 4;
    }

    // Trasera
    if (this.getData(x, y, z - 1) === 0) {
      canShow |= 1 << 5;
    }

    return canShow;
  }

  expandBlock01(arrayIds, startX, startY, startZ, targetSide) {
    const originalId = arrayIds[startY][startZ];
    const targetBlockId = this.getBlock(startX, startY, startZ);
    let endZ = startZ;
    let endY = startY;

    for (let z = startZ + 1; z < 16; z++) {
      if (!this.canExpand(this.getBlock(startX, startY, z), arrayIds[startY][z], targetBlockId, this.visibleSides[Chunk.getIndex(startX, startY, z)], targetSide)) {
        break;
      }
      arrayIds[startY][z] = originalId;
      endZ = z;
    }

    for (let y = startY + 1; y < 256; y++) {
      if (!this.canExpandInRangeZ(arrayIds[y][startZ], startX, y, startZ, endZ, targetBlockId, targetSide)) {
        break;
      }

      this.updateIds01(arrayIds, y, startZ, endZ, originalId);
      endY = y;
    }

    // console.log(`${originalId}: ${startY}: ${startX}x${startZ} -> ${endX}-${endZ}`);

    let side = 0;
    const width = endZ - startZ + 1;
    const height = endY - startY + 1;
    const geometry = new THREE.PlaneGeometry(width, height);
    if (targetSide === 1 << BlockBase.SIDE_RIGHT) {
      side = BlockBase.SIDE_RIGHT;
    }

    if (targetSide === 1 << BlockBase.SIDE_LEFT) {
      side = BlockBase.SIDE_LEFT;
    }

    const realPositionX = this.chunkX * 16 + startX + (side === BlockBase.SIDE_RIGHT ? 1.0 : -0.0);
    const realPositionZ = this.chunkZ * 16 + (startZ + endZ) / 2 + 0.5;

    const block = this.getBlock(startX, startY, startZ);
    const blockInstance = BlockManager.getInstance().blocks[block];
    const material = blockInstance.getMaterial(side);

    const plane = new THREE.Mesh(geometry, material);
    plane.position.set(realPositionX, (startY + endY) / 2 + 0.5, realPositionZ);

    plane.rotation.y = Math.PI / 2;
    if (side === BlockBase.SIDE_LEFT) {
      plane.rotation.y = -Math.PI / 2;
    }

    blockInstance.setupGeometry(geometry, width, height);
    World.getInstance().scene.add(plane);
  }

  expandBlock23(arrayIds, startX, startY, startZ, targetSide) {
    const originalId = arrayIds[startX][startZ];
    const targetBlockId = this.getBlock(startX, startY, startZ);
    let endX = startX;
    let endZ = startZ;

    for (let x = startX + 1; x < 16; x++) {
      if (!this.canExpand(this.getBlock(x, startY, startZ), arrayIds[x][startZ], targetBlockId, this.visibleSides[Chunk.getIndex(x, startY, startZ)], targetSide)) {
        break;
      }
      arrayIds[x][startZ] = originalId;
      endX = x;
    }

    for (let z = startZ + 1; z < 16; z++) {
      if (!this.canExpandInRangeX(arrayIds[startX][z], startX, startY, z, endX, targetBlockId, targetSide)) {
        break;
      }

      this.updateIds23(arrayIds, startX, z, endX, originalId);
      endZ = z;
    }

    // console.log(`${originalId}: ${startY}: ${startX}x${startZ} -> ${endX}-${endZ}`);

    let side = 0;
    const width = endX - startX + 1;
    const height = endZ - startZ + 1;
    const geometry = new THREE.PlaneGeometry(width, height);
    if (targetSide === 1 << BlockBase.SIDE_UP) {
      side = BlockBase.SIDE_UP;
    }

    if (targetSide === 1 << BlockBase.SIDE_DOWN) {
      side = BlockBase.SIDE_DOWN;
    }

    const block = this.getBlock(startX, startY, startZ);

    const blockInstance = BlockManager.getInstance().blocks[block];
    const material = blockInstance.getMaterial(side);
    const plane = new THREE.Mesh(geometry, material);
    const realPositionX = this.chunkX * 16 + (startX + endX) / 2 + 0.5;
    const realPositionZ = this.chunkZ * 16 + (startZ + endZ) / 2 + 0.5;
    plane.position.set(realPositionX, startY + (side === BlockBase.SIDE_UP ? 1.0 : 0.0), realPositionZ); // Posiciona el plano en el punto medio del rectángulo = startY + 0.5;
    plane.rotation.x = -Math.PI / 2;
    if (side === 3) {
      plane.rotation.y = Math.PI;
      plane.rotation.z = Math.PI;
    }
    blockInstance.setupGeometry(geometry, width, height);
    World.getInstance().scene.add(plane);
  }

  expandBlock45(arrayIds, startX, startY, startZ, targetSide) {
    const originalId = arrayIds[startX][startY];
    const targetBlockId = this.getBlock(startX, startY, startZ);
    let endX = startX;
    let endY = startY;

    for (let x = startX + 1; x < 16; x++) {
      if (!this.canExpand(this.getBlock(x, startY, startZ), arrayIds[x][startY], targetBlockId, this.visibleSides[Chunk.getIndex(x, startY, startZ)], targetSide)) {
        break;
      }
      arrayIds[x][startY] = originalId;
      endX = x;
    }

    for (let y = startY + 1; y < 256; y++) {
      if (!this.canExpandInRangeX(arrayIds[startX][y], startX, y, startZ, endX, targetBlockId, targetSide)) {
        break;
      }

      this.updateIds45(arrayIds, startX, y, endX, originalId);
      endY = y;
    }

    // console.log(`${originalId}: ${startY}: ${startX}x${startZ} -> ${endX}-${endZ}`);

    let side = 0;
    const width = endX - startX + 1;
    const height = endY - startY + 1;
    const geometry = new THREE.PlaneGeometry(width, height);
    if (targetSide === 1 << BlockBase.SIDE_FRONT) {
      side = BlockBase.SIDE_FRONT;
    }

    if (targetSide === 1 << BlockBase.SIDE_BACK) {
      side = BlockBase.SIDE_BACK;
    }

    const realPositionX = this.chunkX * 16 + (startX + endX) / 2 + 0.5;
    const realPositionZ = this.chunkZ * 16 + startZ + (side === BlockBase.SIDE_FRONT ? 1.0 : 0.0);
    const block = this.getBlock(startX, startY, startZ);

    const blockInstance = BlockManager.getInstance().blocks[block];
    const material = blockInstance.getMaterial(side);
    const plane = new THREE.Mesh(geometry, material);
    plane.position.set(realPositionX, (startY + endY) / 2 + 0.5, realPositionZ);

    if (side === BlockBase.SIDE_BACK) {
      plane.rotation.x = Math.PI;
      plane.rotation.z = Math.PI;
    }

    blockInstance.setupGeometry(geometry, width, height);
    World.getInstance().scene.add(plane);
  }

  canExpand(block, arrayId, blockId, visibleSides, side) {
    return block !== 0 && arrayId === Chunk.TEST_INIT_VALUE && block === blockId && (visibleSides & side) !== 0;
  }

  canExpandInRangeX(arrayId, startX, y, z, endX, blockId, side) {
    for (let x = startX; x <= endX; x++) {
      if (!this.canExpand(this.getBlock(x, y, z), arrayId, blockId, this.visibleSides[Chunk.getIndex(x, y, z)], side)) {
        return false;
      }
    }
    return true;
  }

  canExpandInRangeZ(arrayId, x, y, startZ, endZ, blockId, side) {
    for (let z = startZ; z <= endZ; z++) {
      if (!this.canExpand(this.getBlock(x, y, z), arrayId, blockId, this.visibleSides[Chunk.getIndex(x, y, z)], side)) {
        return false;
      }
    }
    return true;
  }

  updateIds01(arrayIds, y, startZ, endZ, id) {
    for (let z = startZ; z <= endZ; z++) {
      arrayIds[y][z] = id;
    }
  }

  updateIds23(arrayIds, startX, z, endX, id) {
    for (let x = startX; x <= endX; x++) {
      arrayIds[x][z] = id;
    }
  }

  updateIds45(arrayIds, startX, y, endX, id) {
    for (let x = startX; x <= endX; x++) {
      arrayIds[x][y] = id;
    }
  }
}

Chunk.chunks = {};
