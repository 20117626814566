import * as THREE from "three";
import { World } from "./world";

export function initializeScene() {
  const scene = new THREE.Scene();
  // old FOV: 70
  // new FOV: 60
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0xaad1fe);
  // renderer.shadowMap.enabled = true;

  document.body.style.margin = "0";
  document.body.appendChild(renderer.domElement);

  const resizeHandler = onWindowResize(renderer);
  window.addEventListener("resize", resizeHandler, false);

  return {
    scene,
    renderer,
    cleanup: () => window.removeEventListener("resize", resizeHandler),
  };
}

function onWindowResize(renderer) {
  return function () {
    World.updateCameras = true;

    renderer.setSize(window.innerWidth, window.innerHeight);
  };
}
